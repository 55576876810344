import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c470821a&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=c470821a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c470821a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3FloatingIcon1: require('/app/components/Theme3/FloatingIcon1.vue').default,Theme3FloatingIcon2: require('/app/components/Theme3/FloatingIcon2.vue').default,Theme3AgentFloatingIcon: require('/app/components/Theme3/AgentFloatingIcon.vue').default,Theme3Popup: require('/app/components/Theme3/Popup.vue').default,Theme3MobileHomeSlider: require('/app/components/Theme3/Mobile/HomeSlider.vue').default,Theme3MobileGameMenu: require('/app/components/Theme3/Mobile/GameMenu.vue').default})
